import React from "react"
import Form from "./Form"
// import InfoSection from "./InfoSection"

const FormSection = () => {
  const defaultInfo = {
    title: "Get In Touch",
    contactNumber: "+1 (443) 657-3376",
    contactEmail: "heartvslogicart@gmail.com",
    mainContent:
      "I am always available for work, stimulating ideas and very willing to satisfy your curiosity. Please fill the form and I will be right with you.",
    location: "Blacksburg, Virginia",
  }
  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-sm-6 col-md-5">
            <h4 key="title" className="uppercase">
              {defaultInfo.title}
            </h4>
            <p key="main">{defaultInfo.mainContent}</p>
            <hr />
            <p key="location">{defaultInfo.location}</p>
            <hr />
            <p>
              <span key="email">
                <strong>E:</strong> {defaultInfo.contactEmail}
              </span>
              <br />
              <span key="phone">
                <strong>P:</strong> {defaultInfo.contactNumber}
              </span>
              <br />
            </p>
          </div>
          <Form />
        </div>
      </div>
    </section>
  )
}

export default FormSection
